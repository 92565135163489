import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonField } from '../common-field';
import { CommonTextField } from '../field';

@Component({
	selector: 'common-text-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-text-field.widget.html',
})
export class CommonTextFieldWidget extends CommonField implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonTextField>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: `Required field`,
			minLength: `Text too short.`,
			maxLength: `Text too long.`,
		} : {
			missingRequired: `Required field`,
			minLength: `Text too short.`,
			maxLength: `Text too long.`,
		};

	});


	public readonly renderedModel = computed(() => {
		if (!this.hasFocus()) return this.render(this.field().model());
		return this.field().model();
	});


	public readonly almostMaxLength = computed<{ text: string, error: boolean }>(() => {
		const config = this.field().config();
		const model = this.field().model();

		const calculatedMax = config.max && config.max > 5 ? config.max - 5 : config.max || 0;
		if (config.max && model.length && model.length > calculatedMax) {
			return { text: `${model.length} / ${config.max}`, error: model.length > config.max };
		}
		else return { text: config.multiLine && config.max ? `${model.length} / ${config.max}` : '', error: false };
	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: string) {
		this.field().model.set(value);
	}

	public async onBlur() {
		this.hasFocus.set(false);

		const error = this.validate();
		this.field().error.set(error);

		if (error !== '') return;

		//
		// Format the value for database
		//
		const value = this.field().model();
		const formattedValue = this.parse(value);
		this.field().model.set(formattedValue);

		await this.field().save();

	}


	protected parse(value: string) {
		return this.util.text.fixCase(value, 'sentence-case');
	}

	protected render(value: string) {
		return this.util.text.fixCase(value, 'sentence-case');
	}


	protected validate(): string {

		const value = this.field().model().trim();
		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.min) return labels.missingRequired;
		if (value.length < config.min) return labels.minLength;
		if (config.max && value.length > config.max) return labels.maxLength;

		return '';
	}
}