<!-- @let flyoutContent = flyoutService.content(); Angular 18 -->

@if(flyoutService.content(); as flyoutContent)
{

@if(flyoutContent.type === 'Message')
{
<staff-message-flyout-part [message]="flyoutContent.data"></staff-message-flyout-part>
}
@else if(flyoutContent.type === 'Software')
{
<staff-software-flyout-part [software]="flyoutContent.data"></staff-software-flyout-part>
}
@else if(flyoutContent.type === 'Department'){
<staff-department-flyout-part [department]="flyoutContent.data"></staff-department-flyout-part>
}
@else if(flyoutContent.type === 'Staff'){
<staff-flyout-part [staff]="flyoutContent.data"></staff-flyout-part>
}
@else if(flyoutContent.type === 'UserMenu'){
<staff-user-menu-flyout-part></staff-user-menu-flyout-part>
}
}