<mobile-page-part>

	<div class="info-content">
		@if(pendingMessages(); as buttonText) {
		<button class="btn btn-warning" routerLink='/messages'> {{buttonText}} </button>
		}
		@else {
		{{util.date.formatUTC(currentUTC, 'DOW MMM D, YYYY', 'No Time', 'en-US')}}
		}
	</div>

	
	<div class="home-page software-tiles">

		@for(app of appTiles(); track $index;){
		<a class="btn-bubble" (click)="navigateTo(app)"
			style="text-decoration: none; cursor: pointer;"><!--How to add such styles if not inline as it is a page?-->
			<div class="software-tile">
				<div class="software-tile-logo" [style.background-image]="'url(' + app.logoURI + ')'"></div>
				<div class="software-tile-name">{{app.appName}}</div>
			</div>
		</a>
		}

		<a class="" routerLink='/apps' title="Configure"
			style="text-decoration: none; cursor: pointer;"><!--How to add such styles if not inline as it is a page?-->
			<div class="software-tile software-tile-placeholder">
				<i class="fa-duotone fa-grid-2-plus"></i>
			</div>
		</a>

	</div>

</mobile-page-part>