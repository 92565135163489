import { Component, OnInit, computed, inject, input, signal } from '@angular/core';
import { UtilityService } from '../../../../services';
import { CommonUrlField } from '../field';
import { FormsModule } from '@angular/forms';
import { CommonField } from '../common-field';

@Component({
	selector: 'common-url-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-url-field.widget.html',
})
export class CommonUrlFieldWidget extends CommonField implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonUrlField>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			notValid: 'Spanish invalid url',
		} : {
			missingRequired: `Required field`,
			notValid: `Invalid URL (please include http:// or https://)`,
		};

	});


	public readonly renderedModel = computed(() => {
		return this.render(this.field().model());
	});


	private urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		this.field().model.set(value);
	}


	public async onBlur() {
		this.hasFocus.set(false);

		const error = this.validate();
		this.field().error.set(error);

		if (error !== '') return;

		//
		// Format the value for database
		//
		const value = this.field().model();
		const formattedValue = this.parse(value);
		this.field().model.set(formattedValue);

		await this.field().save();

	}


	protected parse(value: string) {
		return value.trim();
	}

	protected render(value: string) {
		return value;
	}


	protected validate(): string {

		const value = this.field().model().trim();
		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.required) return labels.missingRequired;
		if (value.length && !value.match(this.urlPattern)) return labels.notValid;

		return '';
	}
}