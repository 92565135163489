import { Component, HostBinding, input } from '@angular/core';


@Component({
	selector: 'common-button-column',
	standalone: true,
	template: `<ng-content></ng-content>`,
	styles: `
	:host {

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1em;

		&.button-stretch {

			align-items: stretch;
		}

	}`,
})
export class CommonButtonColumnWidget {

	public readonly stretch = input(false);

	@HostBinding('class.button-stretch') get stretchClass() {
		return this.stretch() ? 'button-stretch' : '';
	}

}