
import { Component, OnInit, computed, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonField } from '../common-field';
import { CommonMultiSelectField } from '../field';

@Component({
	selector: 'common-multiselect-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-multiselect-field.widget.html',
})
export class CommonMultiselectFieldWidget<T> extends CommonField implements OnInit {

	public readonly field = input.required<CommonMultiSelectField<T>>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			invalidSelection: 'Invalid Selection',
		} : {
			missingRequired: `Required field`,
			invalidSelection: 'Invalid Selection',
		};

	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: T, checked: boolean) {

		//
		// On adding an input remove any selection that doesn't exist in the options.
		// E.g. Race other 'O' was removed but we still have the value set.
		//
		const filteredModel = this.field().model().filter(data => this.field().config().options.map(opt => opt.value).includes(data));

		if (checked) {
			const model: T[] = [...filteredModel, value];
			this.field().model.set(model);
		}
		else {
			const model = filteredModel.filter(data => data !== value);
			this.field().model.set(model);
		}
	}

	public async onBlur() {
		this.hasFocus.set(false);

		const error = this.validate();
		this.field().error.set(error);

		if (error !== '') return;

		//
		// Format the value for database
		//
		const values = this.field().model();
		const formattedValue = this.parse(values);
		this.field().model.set(formattedValue);

		await this.field().save();

	}


	protected parse(values: T[]) {
		return values;
	}

	protected render(value: T) {

		if (this.field().model().find(v => v == value)) return true;
		else return false;
	}


	protected validate(): string {

		const values = this.field().model();
		const config = this.field().config();
		const labels = this.labels();

		if (!values.length && config.required) return labels.missingRequired;
		if (values.length && values.find(v => !config.options.map(opt => opt.value).includes(v))) return labels.invalidSelection;

		return '';
	}
}