import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MobileListWithSubItem } from '../mobile-list.widget';

@Component({
	selector: 'mobile-list-link-part',
	imports: [CommonModule, NgbAccordionModule,],
	standalone: true,
	templateUrl: './mobile-list-link.part.html',
	styles: [`
		button {
			width: 100%;
			cursor: pointer;
			text-decoration: none;
			padding: 0.5em 0.75em;
			color: #1f1f1f;
			border-bottom: 1px solid #ddd;
			border-radius: 0;

		

			&:hover {
				background-color: rgba(0, 0, 0, 0.03);
				border-bottom: 1px solid #ddd;
			}

		}
	`],
})
export class MobileListLinkPart {

	public item = input.required<MobileListWithSubItem>();

	public height = computed(() => this.item().subText ? 64 : 54);
}
