import { Component, computed, inject, signal } from '@angular/core';
import { Software } from '@app-interfaces';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppService, FlyoutService } from '../../services';
import { DepartmentSelectorWidget } from '../../widgets';

@Component({
	selector: 'apps-page',
	standalone: true,
	imports: [
		NgbTooltipModule,
		MobilePagePart,
		DepartmentSelectorWidget,
	],
	templateUrl: './apps.page.html',
	styleUrl: './apps.page.scss',
})

export class AppsPage {
	private app = inject(AppService);
	private frame = inject(MobileFrameService);
	private flyoutService = inject(FlyoutService);


	public departmentId = signal(undefined);

	public readonly softwares = computed<{ available: Software[], unavailable: Software[] }>(() => {
		const data = this.app.data();
		const departmentId = this.departmentId();

		let softwares = data.softwares;

		if (departmentId) {
			softwares = data.softwares.filter(software => software.departmentId == departmentId)
		}

		return {
			available: softwares.filter(software => software.isProvisioned),
			unavailable: softwares.filter(software => !software.isProvisioned),
		};

	});


	constructor() {
		this.frame.setUrlMetadata({ url: '/apps', pageName: 'Apps', backUrl: '/' });
	}


	openSoftwareFlyout(software: Software) {
		this.flyoutService.showSoftware(software);
	}
}