import { Component, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'policies-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './policies.page.html'
})

export class PoliciesPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/policies', pageName: 'Policies', backUrl: '/' });
	}

}