import { Injectable, computed, signal } from '@angular/core';

@Injectable()
export abstract class CommonField {

	public readonly hasFocus = signal(false);
	public readonly hadFocus = signal(false);
	public readonly randomId = `field-${Math.floor(Math.random() * 1000)}`;


	public readonly errorToShow = computed(() => {
		const error = this.validate();
		if (error == '') return '';
		if (this.hasFocus() || !this.hadFocus()) return '';
		return error;
	});


	public onFocus() {
		this.hadFocus.set(true);
		this.hasFocus.set(true);
	}

	

	public abstract onBlur(): Promise<void>;
	protected abstract validate(): string;
}