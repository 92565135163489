import { ComponentType } from '@angular/cdk/overlay/index';
import { Injectable, InputSignal, inject, signal } from '@angular/core';
import { Department, Message, Software, Staff } from '@app-interfaces';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app/app.service';
import { StaffFlyoutContainerPart } from './container/flyout-container.part';
import { FlyoutContent } from './flyout-content';


@Injectable({ providedIn: 'root' })
export class FlyoutService {

	private offcanvasService = inject(NgbOffcanvas);
	private app = inject(AppService);
	// private activeOffcanvas = inject(NgbActiveOffcanvas);

	public content = signal<FlyoutContent | undefined>(undefined);

	/**
	 * Shows the message flyout
	 */
	public showUserMenu() {
		this.content.set({
			type: 'UserMenu',
			data: undefined,
		});
		this.openFlyout();
	}
	/**
	 * Shows the message flyout
	 */
	public showMessage(message: Message) {
		this.content.set({
			data: message,
			type: 'Message',
		});
		this.openFlyout();
	}


	/**
	 * Shows the staff flyout
	 */
	public showStaff(staff: Staff) {
		this.content.set({
			data: staff,
			type: 'Staff',
		});
		this.openFlyout();
	}


	/**
	 * Reads the staff record from app and shows the staff flyout
	 */
	public showStaffId(staffId: number) {

		const staff = this.app.data().staff.find(staff => staff.staffId == staffId)!;

		this.content.set({
			data: staff,
			type: 'Staff',
		});
		this.openFlyout();
	}



	/**
	 * Shows the Department flyout
	 */
	public showDepartment(department: Department) {
		this.content.set({
			data: department,
			type: 'Department',
		});
		this.openFlyout();
	}


	/**
	 * Shows the Department flyout
	 */
	public showSoftware(software: Software) {
		this.content.set({
			data: software,
			type: 'Software',
		});
		this.openFlyout();
	}





	private openFlyout() {
		if (!this.offcanvasService.hasOpenOffcanvas()) {

			//
			// The container in which the flyout will be added
			//
			const pageContentElement = document.querySelector('.flyout-container') as HTMLElement;

			if (pageContentElement) {
				this.offcanvasService.open(StaffFlyoutContainerPart, { container: pageContentElement, position: 'end', scroll: false });
			}
		}
	}




	/**
	 * Close any open offcanvas.
	 */
	// close() {
	// 	// this.activeOffcanvas.dismiss();
	// }
}
