import { NgClass } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { DEFAULT_AVATAR_DATAURI } from './default-avatar';
import { MobileFrameService } from '../mobile-frame.service';

@Component({
	selector: 'mobile-avatar-part',
	standalone: true,
	imports: [NgClass],
	templateUrl: './mobile-avatar.part.html',
	styleUrls: ['./mobile-avatar.part.scss'],
})
export class MobileAvatarPart {

	private frame = inject(MobileFrameService);

	public readonly size = input<'30px' | '35px' | '40px' | '50px' | '96px'>('96px');

	public readonly sizeClass = computed(() => {
		if (this.size() == '30px') return 'size30';
		else if (this.size() == '35px') return 'size35';
		else if (this.size() == '40px') return 'size40';
		else if (this.size() == '50px') return 'size50';
		else if (this.size() == '96px') return 'size96';
		else return '';
	});

	public readonly avatar = input<string | undefined>(DEFAULT_AVATAR_DATAURI);

	public dataURI = computed<string>(() => {
		return this.avatar() || DEFAULT_AVATAR_DATAURI;
	});

}