import { AngularEnvironment, AppName, EnvironmentName } from "../env";
import { ENGLISH, SPANISH, UrlLanguageId } from "./url-language-id";

export type BASE_URL
	= 'https://dev.eforall.app'
	| 'https://dev.eparatodos.app'
	| 'https://eforall.app'
	| 'https://eparatodos.app'
	| 'http://localhost:4200'
	| 'http://eparatodos.localhost:4200'
	| 'http://admin.eforall.org'
	| 'http://dev-admin.eforall.org'
	| 'http://staff.eforall.org'
	| 'http://dev-staff.eforall.org'
	| 'http://programs.eforall.org'
	| 'http://dev-programs.eforall.org'
	| 'http://dashboard.eforall.org'
	| 'http://dev-dashboard.eforall.org'
	| 'http://training.eforall.org'
	| 'http://dev-training.eforall.org';


interface BaseUrl {
	env: EnvironmentName,
	appName: AppName,
	lang: UrlLanguageId,
	url: BASE_URL,
}

const BASE_URLS: BaseUrl[] = [
	{ env: 'DEV', appName: 'Public', lang: ENGLISH, url: 'https://dev.eforall.app' },
	{ env: 'DEV', appName: 'Public', lang: SPANISH, url: 'https://dev.eparatodos.app' },
	{ env: 'PROD', appName: 'Public', lang: ENGLISH, url: 'https://eforall.app' },
	{ env: 'PROD', appName: 'Public', lang: SPANISH, url: 'https://eparatodos.app' },
	{ env: 'LOCALHOST', appName: 'Admin', lang: SPANISH, url: 'http://eparatodos.localhost:4200' }, // We don't check for appName when localhost, currently set to Admin
	{ env: 'LOCALHOST', appName: 'Admin', lang: ENGLISH, url: 'http://localhost:4200' },// We don't check for appName when localhost, currently set to Admin
	{ env: 'PROD', appName: 'Admin', lang: ENGLISH, url: 'http://admin.eforall.org' },
	{ env: 'DEV', appName: 'Admin', lang: ENGLISH, url: 'http://dev-admin.eforall.org' },
	{ env: 'PROD', appName: 'Staff', lang: ENGLISH, url: 'http://staff.eforall.org' },
	{ env: 'DEV', appName: 'Staff', lang: ENGLISH, url: 'http://dev-staff.eforall.org' },
	{ env: 'PROD', appName: 'Programs', lang: ENGLISH, url: 'http://programs.eforall.org' },
	{ env: 'DEV', appName: 'Programs', lang: ENGLISH, url: 'http://dev-programs.eforall.org' },
	{ env: 'PROD', appName: 'Dashboard', lang: ENGLISH, url: 'http://dashboard.eforall.org' },
	{ env: 'DEV', appName: 'Dashboard', lang: ENGLISH, url: 'http://dev-dashboard.eforall.org' },
	{ env: 'PROD', appName: 'Training', lang: ENGLISH, url: 'http://training.eforall.org' },
	{ env: 'DEV', appName: 'Training', lang: ENGLISH, url: 'http://dev-training.eforall.org' },

];

/**
 * Get the base part of the app url based on the languageId and environment
 */
export function getBaseUrl(env: AngularEnvironment, languageId: UrlLanguageId): BASE_URL {

	let baseUrl: BaseUrl | undefined;
	if (env.name == 'LOCALHOST') {
		baseUrl = BASE_URLS.find(url => url.env == env.name && url.lang == languageId);
	}
	else {
		baseUrl = BASE_URLS.find(url => url.env == env.name && url.appName == env.appName && url.lang == languageId);
	}

	if (!baseUrl) throw new Error(`UrlService: No base url matches env and language ${languageId}.`);
	return baseUrl.url;
}