<div class="nav-bar">
	<div class="nav-bar-content">
		@for(menuItem of frame.config().navBar; track $index;){
			@if(menuItem.routerLink){
				<a [routerLink]="menuItem.routerLink" [class.active]="isActive(menuItem.routerLink)">
					<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i><span>{{menuItem.label}}</span><strong></strong>
				</a>
			}
			@if(menuItem.url){
				<a [href]="menuItem.url" [target]="menuItem.label">
					<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i><span>{{menuItem.label}}</span><strong></strong>
				</a>
			}
		}
	</div>
</div>