import { Injectable, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AngularEnvironment } from '../env';
import { determineLanguageFromUrl } from './determine-language-and-configuration';
import { BASE_URL, getBaseUrl } from './get-base-url';
import { ENGLISH, SPANISH, UrlLanguageId } from './url-language-id';


@Injectable({ providedIn: 'root' })
export class UrlService {

	private router = inject(Router);

	public readonly languageId = signal<UrlLanguageId>(ENGLISH);
	// public readonly url = signal<string | undefined>(undefined);


	public readonly logo: 'eforall' | 'eparatodos' = 'eforall';
	// public readonly baseUrl: BASE_URL;

	public readonly url = toSignal(
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(event => this.router.url)
		)
	);

	public isSpanish = computed(() => {
		return this.languageId() === SPANISH;
	});


	constructor() {

		//
		// Add these four domains to your hosts file to debug in English/Spanish and EforAll
		// https://www.howtogeek.com/howto/27350/beginner-geek-how-to-edit-your-hosts-file/
		//
		// PC: c:\windows\system32\drivers\etc\hosts
		// MAC: /etc/hosts
		//
		// * local.eforall.app (redundant to localhost)
		// * local.eparatodos.app
		//

		const { languageId, isSpanish } = determineLanguageFromUrl();

		this.languageId.set(languageId);
		this.logo = isSpanish ? 'eparatodos' : 'eforall';


		const body = document.getElementsByTagName('body')[0];
		body?.classList.add(this.logo);
	}

	/**
	 * Deterine the base url for a specific language. The environment and app config
	 * of the running application will also be used.  To get the base url for the
	 * language of the running application, just read the urlService.baseUrl property.
	 * @param languageId 
	 */
	public getBaseUrl(env: AngularEnvironment, languageId: UrlLanguageId): BASE_URL {
		return getBaseUrl(env, languageId);
	}
}