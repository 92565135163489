import { Component, OnInit, computed, inject, input, signal } from '@angular/core';
import { UtilityService } from '../../../../services';
import { CommonPhoneField } from '../field';
import { FormsModule } from '@angular/forms';
import { CommonField } from '../common-field';

@Component({
	selector: 'common-phone-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-phone-field.widget.html',
})
export class CommonPhoneFieldWidget extends CommonField implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonPhoneField>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			minLength: 'Spanish min length',
			maxLength: 'Spanish max length',
			notValid: 'Spanish not valid phone',
		} : {
			missingRequired: `Required field`,
			minLength: `Phone must be at least 10 digits long.`,
			maxLength: `Phone must be shorter than 10 digits.`,
			notValid: `Invalid phone number`,
		};

	});


	public readonly renderedModel = computed(() => {
		return this.render(this.field().model());
	});


	private phonePatternUS = /^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		this.field().model.set(value);
	}


	public async onBlur() {
		this.hasFocus.set(false);

		const error = this.validate();
		this.field().error.set(error);

		if (error !== '') return;

		//
		// Format the value for database
		//
		const value = this.field().model();
		const formattedValue = this.parse(value);
		this.field().model.set(formattedValue);

		await this.field().save();

	}


	protected parse(value: string) {
		let n = value.replace(/\D/g, '');
		if (n.length && n[0] == '1') n = n.slice(1);
		if (n.length == 10) return '+1' + n;
		else return '';
	}

	protected render(value: string) {
		if (this.hasFocus() || this.field().error()) return this.field().model();
		return this.util.phone.format(value);
	}


	protected validate(): string {

		const value = this.field().model().trim();
		const config = this.field().config();
		const labels = this.labels();

		if (value.length == 0 && config.required) return labels.missingRequired;
		if (value.length && !value.match(this.phonePatternUS)) return labels.notValid;

		return '';
	}
}