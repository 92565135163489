import { Component, input } from '@angular/core';
import { Department } from '@app-interfaces';
import { CommonIconTextWidget } from '@eforall/common';

@Component({
	selector: 'staff-department-header',
	standalone: true,
	imports: [CommonIconTextWidget],
	templateUrl: './department-header.widget.html',
	styleUrl: './department-header.widget.scss'
})


export class StaffDepartmentHeaderWidget {

	public department = input.required<Department | undefined>();

}


