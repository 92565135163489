import { Component, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'procedures-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './procedures.page.html'
})

export class ProceduresPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/procedures', pageName: 'Procedures', backUrl: '/' });
	}

}