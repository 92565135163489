import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MobileFrame, MobileLayoutService, MobilePanelPart, } from '@eforall/mobile';


@Component({
	selector: 'staff-mobile-frame',
	standalone: true,
	imports: [
		RouterOutlet,
		MobileFrame,
		MobilePanelPart,
	],
	templateUrl: './staff-mobile.frame.html',
	styleUrl: './staff-mobile.frame.scss'
})
export class StaffMobileFrame {

	public layout = inject(MobileLayoutService);

}
