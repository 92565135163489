import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonField } from '../common-field';
import { CommonNumberField } from '../field';

@Component({
	selector: 'common-number-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-number-field.widget.html',
})
export class CommonNumberFieldWidget extends CommonField implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonNumberField>();

	public min = computed<number>(() => this.field().config().min ?? 0);
	public max = computed<number>(() => this.field().config().max ?? 100);

	public readonly type = computed(() => this.hasFocus() ? 'number' : 'text')


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
			minLength: 'Spanish min length',
			maxLength: 'Spanish max length',
		} : {
			missingRequired: `Required field`,
			minLength: `Number must be greater than ${this.min()}.`,
			maxLength: `Number must be less than ${this.max()}.`,
		};

	});


	public readonly renderedModel = computed(() => {
		return this.render(this.field().model());
	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}


	public onChange(value: string) {
		const num = value === '' ? undefined : parseInt(value);
		this.field().model.set(num);
	}


	public async onBlur() {
		this.hasFocus.set(false);



		const error = this.validate();
		this.field().error.set(error);

		if (error !== '') return;

		//
		// Format the value for database
		//
		const value = this.field().model();
		const formattedValue = this.parse(value);
		this.field().model.set(formattedValue);

		await this.field().save();

	}


	protected parse(value: number | undefined) {
		if (value && value > 0) value = Math.round(value);
		if (value == 0 && this.field().config().saveZeroAsNull) value = undefined;
		return value;
	}

	protected render(value: number | undefined) {
		if (this.hasFocus() || this.field().error()) return value;
		return value?.toLocaleString();
	}


	protected validate(): string {

		let value = this.field().model();
		if (value && value > 0) value = Math.round(value);
		const config = this.field().config();
		const labels = this.labels();

		if (config.required && value == undefined && value == null) return labels.missingRequired;
		if (value !== undefined && value !== null && value > this.max()) return labels.maxLength;
		if (value !== undefined && value !== null && value < this.min()) return labels.minLength;

		return '';
	}
}