import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AnalyticsService } from '../analytics/analytics.service';
import { EnvironmentService } from '../env';
import { SpinnerService } from '../spinner';
import { UtilityService } from '../util';
import { AuthState } from './auth-state';
import { authStateEquality } from './auth-state-equality';
import { getAuthStateChangedHandler } from './get-auth-state-changed-handler';
import { internalSignIn } from './internal-sign-in';


/**
 * A common authentication service used by all EforAll apps.
 * The service will start automatically once the environment is available.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {

	private analytics = inject(AnalyticsService);
	private env = inject(EnvironmentService);
	private router = inject(Router);
	private util = inject(UtilityService);
	private spinnerService = inject(SpinnerService);


	public readonly state = signal<AuthState>(
		{ status: 'pending' },
		{ equal: authStateEquality },
	);

	private loadData: (() => Promise<void>) | undefined = undefined;
	private clearData: (() => Promise<void>) | undefined = undefined;
	private loadDomainData: (() => Promise<void>) | undefined = undefined;
	private clearDomainData: (() => Promise<void>) | undefined = undefined;


	/**
	 * Firebase objects.
	 */
	public app?: FirebaseApp;


	/**
	 * Service that holds the chosen authentication engine.  The service can only be used for
	 * one of the engines and will throw an error if attempted to be used or re-initialized
	 * with a different one.
	 */
	constructor() {
		this.setState(this.state());
		this.start();
	}


	private async start() {

		//
		// Wait until the environment is available
		//
		const environment = await this.env.getEnvironmentPromise();

		//
		// Initialize Firebase
		//
		const firebaseConfig = { ...environment.firebaseConfig };
		const domain = document?.location?.hostname?.toLowerCase();
		if (!domain.includes('localhost')) firebaseConfig.authDomain = domain;

		this.app = initializeApp(firebaseConfig);
		this.analytics.setAnalytics(getAnalytics(this.app));

		//
		// Configure language and persistence
		//
		const auth = getAuth(this.app);
		auth.useDeviceLanguage();
		// await auth.setPersistence(browserLocalPersistence);


		//
		// Listen for changes...
		//
		onAuthStateChanged(
			auth,
			getAuthStateChangedHandler(
				auth,
				this.analytics,
				this.util,
				this.router,
				this.setState.bind(this),
				environment,
			),
			error => {
				this.analytics.logError(error);
				this.util.log.errorMessage(`${error.message}`);
			}
		);

	}



	public signOut() {
		const auth = getAuth(this.app);
		auth.signOut();

		const environment = this.env.getEnvironment();
		internalSignIn(environment);
	}


	public supportsLocalStorage(): boolean {
		let hasWebStorage = false;

		try {
			const test = 'test-for-support';
			localStorage.setItem(test, test)
			hasWebStorage = localStorage.getItem(test) === test;
			localStorage.removeItem(test);
		}
		catch (e) { hasWebStorage = false; }

		return hasWebStorage;
	}


	/**
	 * Get the firebase id token to send along with the payload to functions
	 */
	public async getIdToken(): Promise<string> {

		const auth = this.state();

		if (auth.status == 'signed-in') {
			const token = await auth.user.getIdToken();
			return 'firebase:' + token;
		}
		else {
			throw new Error(`getIdToken() failed because the auth state is ${auth.status}`);
		}
	}


	/**
	 * Sets the state signal and triggers the spinner and data loading
	 */
	private setState(state: AuthState) {
		this.state.set(state);
		const status = state.status;

		if (status == 'pending') {
			this.spinnerService.addSpinner('auth-pending', 'Signing In');
		}
		else {
			this.spinnerService.removeSpinner('auth-pending');
		}

		if (status == 'signed-in') {
			if (this.loadDomainData) this.loadDomainData();
			if (this.loadData) this.loadData();
		}
		else {
			if (this.clearDomainData) this.clearDomainData();
			if (this.clearData) this.clearData();
		}
	}

	/**
	 * If provided, the auth service will call these methods
	 * to load or clear app data when the auth state changes.
	 * 
	 * @param loadData - A function to be called when the status is 'signed-in'
	 * @param clearData - A function to be called when the status is 'signed-out'
	 */
	public setData(
		loadData: () => Promise<void>,
		clearData: () => Promise<void>,
		loadDomainData?: () => Promise<void>,
		clearDomainData?: () => Promise<void>,
	) {
		this.loadDomainData = loadDomainData;
		this.clearDomainData = clearDomainData;
		this.loadData = loadData;
		this.clearData = clearData;
	}

}