import { Component, inject, input, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Staff } from '@app-interfaces';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonIconTextWidget, UtilityService } from '@eforall/common';
import {
	MobileAvatarPart,
	MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart,
} from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FlyoutService } from '../..';
import { StaffDepartmentHeaderWidget } from '../../../widgets';


@Component({
	selector: 'staff-flyout-part',
	standalone: true,
	imports: [
		StaffDepartmentHeaderWidget,
		CommonIconTextWidget,
		CommonButtonColumnWidget,
		CommonButtonRowWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		MobileAvatarPart,
		RouterLink
	],
	templateUrl: './staff-flyout.part.html',
	styleUrl: './staff-flyout.part.scss'
})
export class StaffFlyoutPart {

	public readonly flyoutService = inject(FlyoutService);
	private readonly activeOffcanvas = inject(NgbActiveOffcanvas);
	public readonly util = inject(UtilityService);

	public readonly staff = input.required<Staff>();
	public readonly showContact = signal(false);


	close() {
		this.activeOffcanvas.close();
	}

	openDepartment() {
		const department = this.staff().department;
		if (department) this.flyoutService.showDepartment(department);
	}

	openEmail() {
		window.open(`mailto:${this.staff().email}`, 'Mail');
	}

	openChat() {
		window.open(`https://chat.google.com/room/AAAAlOvAAAc`, 'Tech Support');
	}

	openCall() {
		if (this.staff().mobilePhone) window.open(`tel:${this.staff().mobilePhone}`);
	}

	openText() {
		if (this.staff().mobilePhone) window.open(`sms:${this.staff().mobilePhone}`);
	}

	openStaff(staff: Staff | undefined) {
		if (staff) this.flyoutService.showStaff(staff);
	}

}