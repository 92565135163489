import { Component, computed, inject, model } from '@angular/core';
import { Department } from '@app-interfaces';
import { CommonSelectorItem, CommonSelectorWidget } from '@eforall/common';
import { AppService } from '../../services';


@Component({
	selector: 'department-selector',
	standalone: true,
	imports: [CommonSelectorWidget],
	template: `<common-selector [(selectedId)]='selectedDepartmentId' [items]="items()"></common-selector>`,
})
export class DepartmentSelectorWidget {

	private app = inject(AppService);

	public selectedDepartmentId = model<number | undefined>(undefined);

	public readonly items = computed(() => {

		const items = this.app.data().departments.map(dept => {
			const item: CommonSelectorItem<Department> = {
				id: dept.departmentId,
				label: dept.name,
				iconStyle: 'fa-solid',
				icon: `${dept.icon} color-eforall-navy`,
				data: dept,
			};

			return item;
		});

		items.unshift({ id: undefined, label: 'All Departments', icon: 'fa-circle color-lightgray', iconStyle: 'fa-solid' });

		return items;
	});

	public readonly selectedDepartment = computed(() => {
		return this.app.data().departments.find(dept => this.selectedDepartmentId() == dept.departmentId);
	});
}