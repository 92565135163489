<mobile-page-part [noContentMessage]="noContentMessage()">

	<div class="info-content" style="min-width:240px;">
		<staff-selector [(selectedStaffId)]="selectedStaffId"></staff-selector>
	</div>

	@if(selectedStaff(); as staff) {
	<org-chart-hierarchy-part [staff]="staff"></org-chart-hierarchy-part>
	}

</mobile-page-part>