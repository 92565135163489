import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MobileFrameService } from '../mobile-frame.service';

@Component({
	selector: 'mobile-nav-bar-part',
	standalone: true,
	imports: [RouterModule],
	templateUrl: './mobile-nav-bar.part.html',
	styleUrls: ['./mobile-nav-bar.part.scss'],
})
export class MobileNavBarPart {

	public frame = inject(MobileFrameService);

	private router = inject(Router);


	isActive(routePath: string): boolean {
		return this.router.url === routePath;
	}

}