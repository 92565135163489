@if(field(); as field)
{
<div class="input-group">
	<span class="input-group-text">
		@if(field.saving()){<i class="fa-fw fa-regular fa-spin fa-spinner-third"></i>}
		@else if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
		@else{<i class="fa-fw fa-regular fa-hand-pointer"></i>}
	</span>
	<div class="form-floating">
		<select class="form-select form-select-sm" [id]="randomId" [ngModel]="renderedModel()"
			(ngModelChange)="onChange($event)" (blur)='onBlur()' (focus)="onFocus()">
			<option disabled value="">Select...</option>
			@for(option of field.config().options; track option.value;)
			{
			<option [value]="option.value">{{option.text}}</option>
			}
		</select>
		<label class="color-gray d-flex" [for]="randomId">
			{{field.config().label}}{{!field.config().required ? ' (optional)':''}}
		</label>

	</div>
</div>

@if(errorToShow() !== '')
{
<div class="color-darkred text-end">{{errorToShow()}}</div>
}
}