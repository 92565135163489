import { Component, computed, inject, input } from '@angular/core';
import { Department, Staff } from '@app-interfaces';
import { CommonIconTextWidget } from '@eforall/common';
import {
	MobileAvatarPart,
	MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart,
} from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService, FlyoutService } from '../..';


@Component({
	selector: 'staff-department-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		MobileAvatarPart,
	],
	templateUrl: './department-flyout.part.html',
	styleUrl: './department-flyout.part.scss'
})
export class StaffDepartmentFlyoutPart {
	public flyoutService = inject(FlyoutService);
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	private app = inject(AppService);


	public readonly department = input.required<Department>();

	public departementMembers = computed(() => {
		return this.app.data().staff.filter(staff => this.department().headStaffId !=staff.staffId && staff.departmentId == this.department().departmentId);
	});


	close() {
		this.activeOffcanvas.close();
	}

	openStaff(staff: Staff | undefined) {
		if (staff) this.flyoutService.showStaff(staff);
	}

}