<div class="org-list">

	<div>
		@for(member of ancestors(); track member.staffId) {
		@if(member.staffId === staff().staffId) {
		<button class="btn btn-standard btn-flat" [disabled]="true">
			<i class="fa-fw fa-caret-right fa-solid"></i>
		</button>
		}
		@else {
		<button class="btn btn-primary" (click)="open(member)">
			<i class="fa-fw fa-list-tree fa-regular"></i>
		</button>
		}
		}
	</div>

	<staff-list [staff]="ancestors()" [showAncestorCounts]="true"></staff-list>

</div>


@if(staff().directReports.length) {

<div class="direct-reports">
	<div class="org-list">

		<div>
			@for(member of staff().directReports; track member.staffId) {
			<button class="btn btn-primary" (click)="open(member)">
				<i class="fa-fw fa-list-tree fa-regular"></i>
			</button>
			}
		</div>

		<staff-list [staff]="staff().directReports" [showAncestorCounts]="true"></staff-list>

	</div>
</div>
}