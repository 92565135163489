<mobile-frame>

	@if(layout.dimensions().breakpoint === 'wide-computer'){
	<mobile-panel-part class="left-panel">
		<!--
			<activity-panel></activity-panel>
		-->
	</mobile-panel-part>
	}

	<div class="page-content">
		<router-outlet />
	</div>



	@if(layout.dimensions().breakpoint === 'computer' || layout.dimensions().breakpoint === 'wide-computer'){
	<mobile-panel-part class="right-panel">
		<!--
			<calendar-panel></calendar-panel>
		-->
	</mobile-panel-part>
	}

</mobile-frame>