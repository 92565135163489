<mobile-page-part>

	<div class="info-content" style="min-width: 210px;">
		<department-selector [(selectedDepartmentId)]="departmentId"></department-selector>
	</div>

	<div class="apps-page d-flex flex-column">

		@if(softwares().available.length)
		{
		<div class="mobile-list-container">
			@for(software of softwares().available; track software.softwareId;)
			{
			<a (click)="openSoftwareFlyout(software)" class="d-flex justify-content-left align-items-center gap-3">
				<div class="software-apps-logo" [style.background-image]="'url(' + software.logo + ')'"></div>
				<span>{{software.longName}}</span>
			</a>
			}
		</div>
		}

		@if(softwares().unavailable.length)
		{
		<div>
			<h4 class="px-2">Apps without access:</h4>
			<div class="mobile-list-container">
				@for(software of softwares().unavailable; track software.softwareId;)
				{
				<a (click)="openSoftwareFlyout(software)" class="d-flex justify-content-left align-items-center gap-3">
					<div class="software-apps-logo" [style.background-image]="'url(' + software.logo + ')'"></div>
					<span>{{software.longName}}</span>
				</a>
				}
			</div>
		</div>
		}
	</div>

</mobile-page-part>