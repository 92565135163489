@if(field(); as field)
{
<div class="input-group">
	<span class="input-group-text">
		@if(field.saving()){<i class="fa-fw fa-regular fa-spin fa-spinner-third"></i>}
		@else if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
		@else{<i class="fa-fw fa-regular fa-pen"></i>}
	</span>
	<div class="form-floating">
		@if(field.config().multiLine)
		{
		<textarea class="form-control" [id]="randomId" [placeholder]="field.config().label" [ngModel]="renderedModel()"
			(ngModelChange)="onChange($event)" [disabled]="field.saving()" (blur)='onBlur()' (focus)="onFocus()"
			style="resize: none;" [style.min-height.em]="field.config().minHeight ?? 8"></textarea>
		}
		@else {
		<input type="text" class="form-control" [id]="randomId" [placeholder]="field.config().label"
			[attr.maxLength]="field.config().max" [ngModel]="renderedModel()" (ngModelChange)="onChange($event)"
			[disabled]="field.saving()" (blur)='onBlur()' (focus)="onFocus()">
		}
		<label class="color-gray d-flex" [for]="randomId" style="width: 100%;">
			{{field.config().label}}{{ field.config().label && field.config().min === 0 ? ' (optional)':''}}
			@if(almostMaxLength().text !== ''){
			<div class="ms-auto text-end" [class.color-darkred]="almostMaxLength().error">{{almostMaxLength().text}}
			</div>
			}
		</label>

	</div>
</div>

@if(errorToShow() !== '')
{
<div class="color-darkred text-end">{{errorToShow()}}</div>
}
}