import { Injectable } from '@angular/core';
import { ReplaySubject, lastValueFrom, take } from 'rxjs';
import { AngularEnvironment } from './angular-environment';


@Injectable({ providedIn: 'root' })
export class EnvironmentService {

	private _environment: AngularEnvironment | undefined = undefined;
	private _environment$ = new ReplaySubject<AngularEnvironment>(1);


	/**
	 * Return the environment object. The environment must have been set
	 * first with a call to setEnvironment() or an error will be thrown.
	 */
	getEnvironment() {

		const environment = this._environment;
		if (environment) return environment;

		throw new Error(`EnvironmentService.getEnvironment() read without a prior call to setEnvironment().`);
	}


	/**
	 * Asynchronously wait for the environment to be cached and return it out.
	 */
	async getEnvironmentPromise(): Promise<AngularEnvironment> {

		return lastValueFrom(this._environment$.pipe(take(1)));
	}


	/**
	 * Call this function exactly once in the app root page to initialize the
	 * service with the environment data. Pages and other services will use
	 * this to get a copy of the environment. 
	 */
	setEnvironment(environment: AngularEnvironment) {

		console.log(`EnvironmentService.setEnvironment()`, environment);

		if (this._environment) return;	// Ignore duplicate calls
		if (!environment) throw new Error(`EnvironmentService.setEnvironment() called with an undefined parameter.`);

		this._environment = environment;
		this._environment$.next(environment);
	}

	/**
	 *
	 */
	constructor() {
		console.log(`EnvironmentService.ctor.()`);

	}
}