<mobile-page-part>

	<div class="info-content">
		{{util.date.formatUTC(currentUTC, 'DOW MMM D, YYYY', 'No Time', 'en-US')}}
	</div>

	<common-button-column [stretch]="true">
		<button class='btn btn-primary' routerLink="/staff/members">Staff Members</button>
		<button class='btn btn-primary' routerLink="/staff/departments">Departments</button>
		<button class='btn btn-primary' routerLink="/staff/functions">Functions</button>
		<button class='btn btn-primary' routerLink="/staff/projects">Projects</button>
		<button class='btn btn-primary' routerLink="/staff/sites">Sites</button>
		<button class='btn btn-primary' routerLink="/staff/org-chart">Org Chart</button>
		<button class='btn btn-primary' routerLink="/staff/metrics">Metrics</button>
	</common-button-column>

</mobile-page-part>