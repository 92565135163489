
import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../services';
import { CommonField } from '../common-field';
import { CommonSelectField } from '../field';

@Component({
	selector: 'common-select-field',
	imports: [FormsModule],
	standalone: true,
	templateUrl: './common-select-field.widget.html',
})
export class CommonSelectFieldWidget<T> extends CommonField implements OnInit {

	private util = inject(UtilityService);
	public readonly field = input.required<CommonSelectField<T>>();


	private readonly labels = computed(() => {
		const config = this.field().config();

		return config.isSpanish ? {
			missingRequired: 'Spanish Required',
		} : {
			missingRequired: `Required field`,
		};

	});


	public readonly renderedModel = computed(() => {
		return this.render(this.field().model());
	});


	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: T) {
		this.field().model.set(value);
	}

	public async onBlur() {
		this.hasFocus.set(false);

		const error = this.validate();
		this.field().error.set(error);

		if (error !== '') return;

		//
		// Format the value for database
		//
		const value = this.field().model();
		const formattedValue = this.parse(value);
		this.field().model.set(formattedValue);

		await this.field().save();

	}


	protected parse(value: T) {
		return value;
	}

	protected render(value: T) {
		return value;
	}


	protected validate(): string {

		const value = this.field().model();
		const config = this.field().config();
		const labels = this.labels();

		if (!value && config.required) return labels.missingRequired;

		return '';
	}
}