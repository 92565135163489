import { Component, computed, effect, inject, model, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Staff } from '@app-interfaces';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AppService } from 'services';
import { StaffSelectorWidget } from 'widgets';
import { OrgChartHierarchyPart } from './org-chart-hierarchy.part';


@Component({
	selector: 'org-chart-page',
	standalone: true,
	imports: [
		OrgChartHierarchyPart,
		MobilePagePart,
		StaffSelectorWidget,
	],
	templateUrl: './org-chart.page.html'
})

export class OrgChartPage {

	private frame = inject(MobileFrameService);
	private app = inject(AppService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);


	public readonly selectedStaffId = model(0);

	public readonly selectedStaff = computed(() => {
		const staffId = this.selectedStaffId();
		const data = this.app.data();
		return data.staff.find(staff => staff.staffId == staffId);
	});

	public readonly noContentMessage = signal('');

	constructor() {

		//
		// Get the staffId from the route
		//
		const staffId: number = this.route.snapshot.params['staffId'] ?? 0;
		this.selectedStaffId.set(staffId);

		this.route.params.subscribe(params => {
			const staffId = params['staffId'];
			this.selectedStaffId.set(staffId);
		})

		//
		// See if there is a staff object for that number
		// and set the no content message if not
		//
		const staff = this.selectedStaff();
		if (!staff) this.noContentMessage.set('Select a Staff Member');

		//
		// Set url metadata for each variation
		//
		this.frame.setUrlMetadata({ url: '/staff/org-chart', pageName: 'Org Chart', backUrl: '/staff' });
		for (const staff of this.app.data().staff) {
			this.setStaffUrlMetadata(staff);
		}

		//
		// Check for a change and navigate
		//
		effect(() => {
			const staff = this.selectedStaff();
			if (staff) this.router.navigate([`/staff/org-chart/${staff.staffId}`]);
		});
	}


	private setStaffUrlMetadata(staff: Staff) {

		//
		// Set the title and header
		//
		const url = `/staff/org-chart/${staff.staffId}`;
		const pageName = staff.fullName;
		const header = 'Org Chart';


		this.frame.setUrlMetadata({ url, pageName, header, backUrl: '/staff' });
	}
}