import { Component, inject, input } from '@angular/core';
import { Staff } from '@app-interfaces';
import { MobileAvatarPart } from '@eforall/mobile';
import { FlyoutService } from '../../services';


@Component({
	selector: 'staff-list',
	standalone: true,
	imports: [
		MobileAvatarPart,
	],
	templateUrl: './staff-list.widget.html',
	styles: `
	.ancestor-count {
		position: relative;
		> div {
			position: absolute;
			right: 8px;
			top: 5px;
			z-index: 100;
			padding: 1px 3px 0 3px;
			border: solid 1px rgba(0, 0, 0, 0.3);
			background-color: rgba(255, 127, 127, 0.1);
			border-radius: 5px;
			width: 2em;
			text-align: center;
		}
	}
	`,
})

export class StaffListWidget {

	private flyoutService = inject(FlyoutService);

	public readonly staff = input.required<Staff[]>();
	public readonly showAncestorCounts = input(false);


	openStaffFlyout(staff: Staff) {
		this.flyoutService.showStaff(staff);
	}
}