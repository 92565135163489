import { Injectable, inject } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";
import { EnvironmentService } from '../env';


@Injectable({ providedIn: 'root' })
export class GoogleApiService {
	private env = inject(EnvironmentService);

	private _loader: Loader;

	constructor() {

		this._loader = new Loader({
			apiKey: this.env.getEnvironment().firebaseConfig.apiKey,
			version: "weekly",
			//...additionalOptions,
		});
	}

	public async getMaps(): Promise<google.maps.MapsLibrary> {
		if (!this._loader) throw new Error('getMaps() called before Loader was initialised.');
		const googleMaps = await this._loader.importLibrary('maps');
		return googleMaps;
	}


	public async getPlaces(): Promise<google.maps.PlacesLibrary> {
		if (!this._loader) throw new Error('getPlaces() called before Loader was initialised.');

		const googlePlaces = await this._loader.importLibrary('places');
		return googlePlaces;
	}

}