export interface MobileMenuItem {
	label: string,
	iconStyle: 'fa-regular' | 'fa-solid' | 'fa-duotone',
	icon: string,
	iconColor?: string,
	/** if internal link add RouterLink */
	routerLink?: string,
	/** if external link add URL */
	url?: string,
}

export const EMPTY_MOBILE_FRAME_CONFIG: MobileFrameConfig = {
	user: { publicUserId: 0, staffId: 0, firstName: '', lastName: '', email: '', avatar: '', mobilePhone: '', department: undefined},
	navBar: [], openUserMenu: () => { },
};

export interface MobileFrameConfig {
	user: {
		staffId?: number,
		publicUserId?: number,
		firstName: string,
		lastName: string,
		email: string,
		avatar: string | undefined,
		mobilePhone: string | undefined,
		department: {
			departmentId: number,
			name: string,
			icon: string,
		} | undefined,
	},
	/**
	 * The bottom sticky menu
	 */
	navBar: MobileMenuItem[],


	openUserMenu: () => void,

}