import { Component } from '@angular/core';
import { MobilePagePart } from '../../frame/mobile-page.part';

@Component({
	selector: 'mobile-error-404-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	template: '<mobile-page-part noContentMessage="Page not found"></mobile-page-part>',
})
export class MobileError404Page {

}
