@if(department(); as department)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<div class="d-flex align-items-center ps-1">
			<common-icon-text iconStyle='fa-solid' [icon]="department.icon" [text]="department.name" spacing="large"
				iconSize="large">
			</common-icon-text>
		</div>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<h4 class="d-flex justify-content-center">
			{{department.name}} Department
		</h4>

		<div>
			<h4 class="px-2">Department Head</h4>
			<div class="mobile-list-container">
				<a (click)="openStaff(department.head)" class="d-flex justify-content-left align-items-center gap-3">
					<mobile-avatar-part [avatar]="department.head.avatar" size="35px"></mobile-avatar-part>
					<div>
						<div>{{department.head.firstName}} {{department.head.lastName}}</div>
						<small [class.missing-data]="!department.head.jobRoleId">{{ department.head.jobRole?.en || 'Missing Role' }}</small>
					</div>
				</a>
			</div>
		</div>

		@if(departementMembers().length){
		<div>
			<h4 class="px-2">Members</h4>

			<div class="mobile-list-container">
				@for(member of departementMembers(); track member.staffId;){
				<a (click)="openStaff(member)" class="d-flex justify-content-left align-items-center gap-3">
					<mobile-avatar-part [avatar]="member.avatar" size="35px"></mobile-avatar-part>
					<div>
						<div>{{member.firstName}} {{member.lastName}}</div>
						<small [class.missing-data]="!member.jobRoleId">{{ member.jobRole?.en || 'Missing Role'}}</small>
					</div>
				</a>
				}
			</div>
		</div>
		}
	</mobile-flyout-body-part>

</mobile-flyout-frame>
}