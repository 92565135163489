import { Component, inject, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MobileLayoutService } from '../layout/mobile-layout.service';


@Component({
	selector: 'mobile-flyout-frame',
	standalone: true,
	imports: [RouterModule, NgbTooltip],
	templateUrl: './mobile-flyout.frame.html',
	styleUrl: './mobile-flyout.frame.scss'
})
export class MobileFlyoutFrame {
	public layout = inject(MobileLayoutService);

	public readonly closed = output();

}