import { ENGLISH, SPANISH, UrlLanguageId } from "./url-language-id";

/**
 * Check the domain name used and return the associated language.
 */
export function determineLanguageFromUrl() {

	const hostname: string = document?.location?.hostname?.toLowerCase();

	const isSpanish = ([
		'yo.localhost',
		'yo.eparatodos.org',
		'yo-dev.eparatodos.org',
		'eparatodos.app',
		'dev.eparatodos.app',
		'eparatodos.localhost',
	].includes(hostname));

	const languageId:UrlLanguageId = isSpanish ? SPANISH : ENGLISH;

	return { languageId, isSpanish };
}