import { Component, computed, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { Staff } from '@app-interfaces';
import { StaffListWidget } from '../../../widgets';


@Component({
	selector: 'org-chart-hierarchy-part',
	standalone: true,
	imports: [
		StaffListWidget
	],
	templateUrl: './org-chart-hierarchy.part.html',
	styleUrl: './org-chart-hierarchy.part.scss',
})
export class OrgChartHierarchyPart {

	public readonly router = inject(Router);

	public readonly staff = input.required<Staff>();

	public readonly ancestors = computed(() => {

		let staff = this.staff();

		const list: Staff[] = [staff];

		while (staff.manager) {
			list.unshift(staff.manager);
			staff = staff.manager;
		}

		return list;
	});


	open(staff: Staff) {
		this.router.navigate([`/staff/org-chart/${staff.staffId}`])
	}

}