import { Injectable, inject } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';

@Injectable({ providedIn: 'root' })
export class MarkdownOverrideService {

	private markdownService = inject(MarkdownService);

	constructor() {
		this.markdownService.renderer.link = (href: string, title: string, text: string) => {
			if (href.includes('assets/agreements')) return '<a target="_blank" download href="' + href + '">' + text + '</a>';
			else return '<a target="_blank" href="' + href + '">' + text + '</a>';
		};
	}
}