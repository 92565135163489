import { Component, inject } from '@angular/core';
import { MobileLayoutService } from '../../../frame/layout/mobile-layout.service';


@Component({
	selector: 'mobile-scrollable-content',
	standalone: true,
	templateUrl: './mobile-scrollable-content.widget.html',
	styleUrl: './mobile-scrollable-content.widget.scss'
})


export class MobileScrollableContentWidget {

	public layout = inject(MobileLayoutService);

}


