@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<div class="d-flex align-items-center">
			@if(config.user.department){
			<common-icon-text iconStyle='fa-solid' [icon]="config.user.department.icon"
				[text]="config.user.department.name" spacing="large" iconSize="large">
			</common-icon-text>
			}
			@else {
			<div>(No Department)</div>
			}
		</div>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="d-flex flex-column align-items-center gap-2">
			<a class='user-avatar'>
				<mobile-avatar-part [avatar]="config.user.avatar"></mobile-avatar-part>
				<h4 class="pt-3 text-uppercase">{{config.user.firstName}}</h4>
				<p>{{config.user.lastName}}</p>
			</a>
		</div>


		<div class="menu-items">
			@for(menuItem of menu; track $index;){
			@if(menuItem.routerLink){
			<a [routerLink]="menuItem.routerLink" (click)="close()">
				<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i>
				<span>{{menuItem.label}}</span>
			</a>
			}
			@if(menuItem.url){
			<a [href]="menuItem.url" [target]='menuItem.label' (click)="close()">
				<i class="{{menuItem.iconStyle}} {{menuItem.icon}} {{menuItem.iconColor}}"></i>
				<span>{{menuItem.label}}</span>
			</a>
			}
			}

			<a (click)="signOut()">
				<i class="fa-duotone fa-left-from-bracket"></i>
				<span>Sign Out</span>
			</a>
		</div>
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}