import { Component, inject } from "@angular/core";
import { MobileLayoutService } from "../layout/mobile-layout.service";

@Component({
	selector: 'mobile-flyout-body-part',
	standalone: true,
	template: `<div class="mobile-flyout-body"><ng-content></ng-content></div>`,
	styles: [` 
		.mobile-flyout-body {
			display: flex;
			gap: 1.5em;
			flex-direction: column;
			padding: 1em;
		}
	`]
})
export class MobileFlyoutBodyPart {
	public layout = inject(MobileLayoutService);

}