import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { AuthService, ErrorService, SpinnerService } from '@eforall/common';
import { MobileLayoutPart } from './layout/mobile-layout.part';
import { MobileLayoutService } from './layout/mobile-layout.service';


@Component({
	selector: 'mobile-frame',
	standalone: true,
	imports: [
		CommonModule,
		MobileLayoutPart,
	],
	templateUrl: './mobile.frame.html',
	styleUrl: './mobile.frame.scss'
})
export class MobileFrame {

	public errorService = inject(ErrorService);
	public auth = inject(AuthService);
	public spinner = inject(SpinnerService);
	public layout = inject(MobileLayoutService);

	public cssClass = computed(() =>

		this.layout.dimensions().breakpoint == 'wide-computer' ? 'mobile-frame computer-mobile-frame wide-computer' :
			this.layout.dimensions().breakpoint == 'computer' ?
				'mobile-frame computer-mobile-frame' : this.layout.dimensions().breakpoint == 'tablet' ?
					'mobile-frame tablet-mobile-frame' : 'mobile-frame phone-mobile-frame'
	)

}
