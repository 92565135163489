import { Component, computed, effect, inject, signal } from '@angular/core';
import { Staff } from '@app-interfaces';
import { MobileAvatarPart, MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AppService, FlyoutService } from '../../../services';
import { DepartmentSelectorWidget, StaffListWidget } from '../../../widgets';


@Component({
	selector: 'members-page',
	standalone: true,
	imports: [
		MobileAvatarPart,
		MobilePagePart,
		DepartmentSelectorWidget,
		StaffListWidget,
	],
	templateUrl: './members.page.html'
})
export class MembersPage {

	private frame = inject(MobileFrameService);
	private flyoutService = inject(FlyoutService);
	public app = inject(AppService);

	public departmentId = signal(undefined);


	public readonly staffMembers = computed<Staff[]>(() => {
		const data = this.app.data();
		const departmentId = this.departmentId();

		let members = data.staff;

		if (departmentId) {
			members = data.staff.filter(staff => staff.departmentId == departmentId)
		}

		return members;

	});


	constructor() {

		this.frame.setUrlMetadata({ url: '/staff/members', pageName: 'Staff Members', backUrl: '/staff', header: this.getHeader() });

		effect(() => {
			this.frame.setHeader(this.getHeader());
		});
	}


	getHeader() {
		const members = this.staffMembers();
		return members.length == 1 ? '1 Staff Member' : members.length + ` Staff Members`;
	}

}