import { Component, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'calendar-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './calendar.page.html'
})

export class CalendarPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/calendar', pageName: 'Calendar', backUrl: '/' });
	}

}