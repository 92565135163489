@if(staff(); as staff)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<staff-department-header [department]="staff.department"></staff-department-header>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<a class='staff-avatar d-flex justify-content-left align-items-center gap-3'>
			<mobile-avatar-part size="50px" [avatar]="staff.avatar"></mobile-avatar-part>
			<div>
				<div class="staff-name">{{staff.firstName}} {{staff.lastName}}</div>
				<div [class.missing-data]="!staff.jobRoleId">{{ staff.jobRole?.en || 'Missing Role' }}</div>
			</div>
		</a>


		<common-button-row class="justify-content-center my-2">

			@if(showContact() === false) {

			<button class="btn" [class.btn-primary]="staff.departmentId" [class.btn-standard]="!staff.departmentId"
				(click)="openDepartment()" [disabled]="!staff.departmentId">
				<common-icon-text iconStyle="fa-solid" [icon]="staff.department?.icon || 'fa-circle-dashed'" text="Dept"
					spacing="large"></common-icon-text>
			</button>

			<button class="btn btn-primary" routerLink="/staff/org-chart/{{staff.staffId}}" (click)="close()">
				<common-icon-text icon="fa-list-tree" text="Org Chart" spacing="large"></common-icon-text>
			</button>

			<button class="btn" [class.btn-standard]="true" [disabled]="true">
				<common-icon-text icon="fa-address-card" text="Role" spacing="large"></common-icon-text>
			</button>

			<button class="btn btn-secondary" (click)="showContact.set(true)">
				<common-icon-text icon="fa-comments" text="Contact" spacing="large"></common-icon-text>
			</button>

			}


			@if(showContact() === true) {

			<button class="btn btn-primary" (click)="openEmail()">
				<common-icon-text icon="fa-envelope" text="Email" spacing="large"></common-icon-text>
			</button>

			<button class="btn" [class.btn-standard]="!staff.mobilePhone" [class.btn-primary]="staff.mobilePhone"
				(click)="openCall()" [disabled]="!staff.mobilePhone">
				<common-icon-text icon="fa-phone" text="Call" spacing="large"></common-icon-text>
			</button>

			<button class="btn" [class.btn-standard]="!staff.mobilePhone" [class.btn-primary]="staff.mobilePhone"
				(click)="openText()" [disabled]="!staff.mobilePhone">
				<common-icon-text icon="fa-phone" text="Text" spacing="large"></common-icon-text>
			</button>

			<button class="btn btn-standard" (click)="openChat()" [disabled]="true">
				<common-icon-text icon="fa-message" text="Chat" spacing="large"></common-icon-text>
			</button>

			<button class="btn btn-secondary" (click)="showContact.set(false)">
				<common-icon-text icon="fa-x" text="Cancel" spacing="large"></common-icon-text>
			</button>

			}

		</common-button-row>

		<table class="table table-bordered mb-0">
			<tr>
				<td>Staff Type:</td>
				<td>{{staff.staffType}}</td>
			</tr>
			<tr>
				<td>Email:</td>
				<td>{{staff.email}}</td>
			</tr>
			<tr>
				<td>Phone:</td>
				<td [class.missing-data]="!staff.mobilePhone">{{staff.mobilePhone ?
					util.phone.format(staff.mobilePhone): 'Missing Phone'}}</td>
			</tr>
			<tr>
				<td>Start Day:</td>
				<td [class.missing-data]="!staff.startDay">{{staff.startDay ?
					util.date.formatYearMonthDay(staff.startDay) : 'Missing Start Day'}}</td>
			</tr>
			<tr>
				<td>Birthday:</td>
				<td [class.missing-data]="staff.isBirthDayShared && !staff.birthDay">{{staff.isBirthDayShared ?
					staff.birthDay ? util.date.formatMonthDay(staff.birthDay): 'Missing Birthday' : 'Not Shared'}}</td>
			</tr>
			<tr>
				<td>Pronouns:</td>
				<td [class.missing-data]="!staff.pronounsId">{{staff.pronouns ?? 'Missing Pronouns'}}</td>
			</tr>

		</table>


		@if(staff.manager; as manager){
		<div>
			<h4 class="px-2">Manager</h4>

			<div class="mobile-list-container">
				<a (click)="openStaff(manager)" class="d-flex justify-content-left align-items-center gap-3">
					<mobile-avatar-part [avatar]="manager.avatar" size="35px"></mobile-avatar-part>
					<div>
						<div>{{manager.firstName}} {{manager.lastName}}</div>
						<small [class.missing-data]="!manager.jobRoleId">{{ manager.jobRole?.en || 'Missing Role'
							}}</small>
					</div>
				</a>
			</div>
		</div>
		}


		@if(staff.directReports?.length){
		<div>
			<h4 class="px-2">Direct Reports</h4>
			<div class="mobile-list-container">
				@for(report of staff.directReports; track report.staffId;){
				<a (click)="openStaff(report)" class="d-flex justify-content-left align-items-center gap-3">
					<mobile-avatar-part [avatar]="report.avatar" size="35px"></mobile-avatar-part>
					<div>
						<div>{{report.firstName}} {{report.lastName}}</div>
						<small [class.missing-data]="!report.jobRoleId">{{ report.jobRole?.en || 'Missing Role'
							}}</small>
					</div>
				</a>
				}
			</div>
		</div>
		}

		@if(staff.teams?.length){
		<div>
			<button class="btn btn-standard">
				<common-icon-text icon="fa-users" text="Teams" spacing="large"></common-icon-text>
			</button>
		</div>
		}

	</mobile-flyout-body-part>

</mobile-flyout-frame>
}