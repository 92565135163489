import { Injectable, Signal, computed, effect, inject, signal } from '@angular/core';
import { UrlService } from '@eforall/common';
import { EMPTY_MOBILE_FRAME_CONFIG, MobileFrameConfig } from './mobile-frame-config';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface UrlMetadata {
	url: string,
	backUrl: string,
	pageName: string,

	/**
	 * If provided, this will be displayed in the header instead of the pageName.
	 * This can be set dynamically on the page with setHeader().
	 */
	header?: string,
}


@Injectable({ providedIn: 'root' })
export class MobileFrameService {

	private router = inject(Router);
	public urlService = inject(UrlService);
	private title = inject(Title);


	private readonly _urlDataMap = signal<{ [index: string]: UrlMetadata }>({});


	public urlData = computed(() => {
		const url = this.urlService.url() ?? '';
		const map = this._urlDataMap();
		return map[url];
	});


	private _config = signal<Signal<MobileFrameConfig>>(signal(EMPTY_MOBILE_FRAME_CONFIG));


	public config = computed(() => {
		const _config = this._config();
		return _config();
	});


	constructor() {
		effect(() => {
			const data = this.urlData();
			const title = data?.pageName ?? 'Staff Home';
			this.title.setTitle(title);
		});
	}


	public initialize(config: Signal<MobileFrameConfig>) {
		this._config.set(config);
	}


	navigateUp(metadata: UrlMetadata) {
		this.router.navigate([metadata.backUrl]);
	}


	public setUrlMetadata(metadata: UrlMetadata) {
		const map = { ...this._urlDataMap() };
		map[metadata.url] = metadata;
		this._urlDataMap.set(map);
	}


	/**
	 * Change the header text at the top of the page. This does
	 * not change the pageName that is shown in borwser history.
	 */
	public setHeader(header: string) {
		const urlData = this.urlData();
		urlData!.header = header;
		this._urlDataMap.set({ ...this._urlDataMap() });
	}
}