import { Component, input } from "@angular/core";


@Component({
	selector: 'mobile-page-part',
	standalone: true,
	template: `
		@if(hideInfoBar() === false){
		<div class="info-bar">
			<div></div>
			<div class="info-content"><ng-content select="div.info-content"></ng-content></div>
			<div></div>
		</div>
		}
		@if(noContentMessage(); as message) {
			<div class='no-content-message'>{{ message }}</div>
		}
		@else {
			<ng-content></ng-content>
		}
	`,
	styles: [` 
		:host {

			display: grid;
			gap: 20px;
			grid-template-rows: 40px auto;

			.info-bar {
				color: #fff;
				font-size: 1.25em;
				display: grid;
				grid-template-columns: 1fr auto 1fr;
				div.info-content {
				}
			}

			.no-content-message {
				color: rgb(0, 163, 224);
				padding-top: 3em;
				text-align: center;
			}
		}

		:host.hide-info-bar {
			grid-template-rows: auto;
			.info-bar {
				display: none;
			}
		}
	`],
	host: {
		'[class.hide-info-bar]': 'hideInfoBar()'
	}
})
export class MobilePagePart {

	/**
	 * If there is no content to show in the <ng-content> then a message can be set here
	 * which will be shown instead.  If text is provided, the <ng-content> will not be
	 * rendered, even if there was something projected.
	 */
	public noContentMessage = input<string | undefined>(undefined);
	public hideInfoBar = input<boolean>(false);
}