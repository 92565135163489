import { Injectable, signal } from '@angular/core';


export interface Spinner {
	key: string,
	text: string,
}


@Injectable({ providedIn: 'root' })
export class SpinnerService {

	private readonly _activeSpinners: { spinner: Spinner, start: number }[] = [];
	private readonly _current = signal<Spinner | undefined>(undefined);

	/** The current spinner being shown if there is one. */
	public readonly current = this._current.asReadonly();

	private _logs: string[] = [];


	/**
	 * Add a named spinner. The spinner will run until removeSpinner is called with the same key.
	 */
	public addSpinner(key: string, text: string) {

		const spinner: Spinner = { key, text };
		this.removeSpinner(key);

		this._activeSpinners.push({ spinner, start: new Date().getTime() });

		this._setCurrentSpinner();
	}


	/**
	 * Remove an existing spinner. The key should be exactly the same as what was passed to addSpinner.
	 */
	public removeSpinner(key: string) {

		const index = this._activeSpinners.findIndex(active => active.spinner.key == key);
		const removed = this._activeSpinners[index];

		if (removed) {

			this._activeSpinners.splice(index, 1);

			const elapsed = (new Date()).getTime() - (removed?.start || 0);

			const lengthIndicator = '▬'.repeat(Math.floor(elapsed / 100));
			const log = `${removed.spinner.key || ''} - ${elapsed.toLocaleString()}ms ${lengthIndicator}`;
			this._logs.push(log);

			this._writeLogToConsole(log);
		}

		this._setCurrentSpinner();
	}


	private _setCurrentSpinner() {
		if (this._activeSpinners.length == 0) this._current.set(undefined);
		else this._current.set(this._activeSpinners[this._activeSpinners.length - 1]?.spinner);
	}


	/**
	 * Write the full log of spinner activity to the console.
	 */
	public writeLogsToConsole() {
		for (const log of this._logs) {
			this._writeLogToConsole(log);
		}
	}


	/**
	 * Log details of one spinner. If it is the beginning or end of a run from loading singletons then group together.
	 */
	private _writeLogToConsole(log: string) {
		console.log(`s: ${log}`);
	}


	/**
	 * Set the spinner request count to zero and force the spinner to stop.
	 */
	public forceOff() {

		const spinnersForcedOff = '"' + this._activeSpinners.map(active => active.spinner.key).join('" "') + '"';

		if (this._activeSpinners.length) {
			let msg = `s: 1 spinner forced off: ${spinnersForcedOff}`;
			if (this._activeSpinners.length > 1) msg = `s: ${this._activeSpinners.length} spinners forced off: ${spinnersForcedOff}`;

			console.log(msg);
			this._logs.push(msg);
		}

		//
		// Empty the array
		//
		this._activeSpinners.splice(0, this._activeSpinners.length);
	}
}