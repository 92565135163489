@if(auth.state().status === 'signed-in') {
<div [class]="cssClass()">

	@if(layout.dimensions().breakpoint === 'wide-computer'){
	<ng-content select='mobile-panel-part.left-panel'></ng-content>
	}

	<mobile-layout-part>
		<ng-content select='div.page-content'></ng-content>
	</mobile-layout-part>

	@if(layout.dimensions().breakpoint === 'computer' || layout.dimensions().breakpoint === 'wide-computer'){
	<ng-content select='mobile-panel-part.right-panel'></ng-content>
	}

</div>
}