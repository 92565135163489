import { Component, HostBinding, input } from '@angular/core';


@Component({
	selector: 'common-button-row',
	standalone: true,
	template: `<ng-content></ng-content>`,
	styles: `
	:host {

		display: flex;
		gap: 1em;
		flex-wrap: wrap;

		&.button-stretch {

			flex-wrap: nowrap;

			::ng-deep button {
				flex: 1;
			}
		}

		&.button-center {
			justify-content: center;
		}
	}`,
})
export class CommonButtonRowWidget {

	public readonly stretch = input(false);
	public readonly center = input(false);

	@HostBinding('class.button-stretch') get stretchClass() {
		return this.stretch() ? 'button-stretch' : '';
	}

	@HostBinding('class.button-center') get centerClass() {
		return this.center() ? 'button-center' : '';
	}

}