<div class="mobile-flyout-frame">
	<div class="offcanvas-header">
		<ng-content select="mobile-flyout-header-part"></ng-content>
		<button class='close-btn' ngbTooltip='Close' aria-label="Close" (click)="closed.emit()">
			<i class="fa-solid fa-xmark"></i>
		</button>
	</div>


	<div class="offcanvas-body">
		<ng-content select="mobile-flyout-body-part"></ng-content>
	</div>

	<div class="offcanvas-footer">
		<ng-content select="mobile-flyout-footer-part"></ng-content>
	</div>
</div>