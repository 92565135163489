import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonButtonColumnWidget, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';


@Component({
	selector: 'staff-page',
	standalone: true,
	imports: [
		CommonButtonColumnWidget,
		MobilePagePart,
		RouterModule,
	],
	templateUrl: './staff.page.html',
	styles: `
	:host {
		common-button-column {
			width: 200px;
			margin: 2em auto;
		}
	}
	`,
})

export class StaffPage {

	private frame = inject(MobileFrameService);
	public util = inject(UtilityService);

	public currentUTC = Date.now() / 1000;
	

	constructor() {
		this.frame.setUrlMetadata({ url: '/staff', pageName: 'Staff', backUrl: '/' });
	}

}