import { Component, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'profile-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './profile.page.html'
})

export class ProfilePage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({ url: '/profile', pageName: 'Profile', backUrl: '/' });
	}

}