import { Component } from "@angular/core";

@Component({
	selector: 'mobile-flyout-footer-part',
	standalone: true,
	template: `<div class="mobile-flyout-footer"><ng-content></ng-content></div>`,
	styles: [` 
		.mobile-flyout-footer {
			padding: 0px;
		}
	`]
})
export class MobileFlyoutFooterPart {

}